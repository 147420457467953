import './font.css'

import { fontFamilyBase } from '@farol-ds/tokens'
import Head from 'next/head'

/**
 * This component is responsible for setting up the Inter font family with optimized loading.
 * It uses Google Fonts' CSS to load the font family.
 *
 * This approach allows us to:
 * - Leverage Google Fonts' CDN and caching
 * - Automatically handle font loading and subsetting
 * - Benefit from Google's font optimization
 *
 * @returns A style component that injects the font configuration globally
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextFarolFontProps = {}

export const NextFarolFont = (_: NextFarolFontProps) => {
  return (
    <>
      <Head>
        <link rel="preconnect" href="https://assets.jusbr.com" crossOrigin="anonymous" />

        {/* preload latin only */}
        {/* eslint-disable-next-line @next/next/google-font-preconnect */}
        <link
          rel="preload"
          href="https://assets.jusbr.com/farol/fonts/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1ZL7W0Q5nw.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>

      <style jsx global>{`
        :root {
          --fds-font-family-base: 'Inter', 'Inter-fallback', ${fontFamilyBase};
        }
      `}</style>
    </>
  )
}
